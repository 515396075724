<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block />

        {{ page_title }} #{{ carId }}

        <router-link
          v-if="car && [1, 2, 4, 7, 8, 9, 13].includes($store.getters['auth/authUser'].role.id) || car.creator.id === $store.getters['auth/authUser'].id"
          :to="{ name: 'car.edit', params: {carId: car.id }}"
          class="btn btn-primary pull-right"
          title="Редактировать">
          <i class="fa fa-edit"></i>
        </router-link>
      </h3>
    </div>

    <div class="box-body">
      <div class="row" v-if="car">
        <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div class="box box-primary">
            <div class="box-body">
              <h5 class="box-title pointer" v-on:click="toggleFormCar">
                <a class="collapsed">
                  <i v-bind:class="show_form_car ? 'fa-caret-square-up' : 'fa-caret-square-down'"
                     class="font-scale-3 text-blue fas padding-r-5" />
                  <strong class="d-lg-inline d-md-none">Информация</strong>

                  <template v-if="car.specification.reg_number">
                    <span class="label label-primary font-small mt-1 font-medium pull-right">
                      {{ car.specification.reg_number }}
                    </span>
                  </template>
                </a>
              </h5>

              <transition name="fade">
                <div class="collapse" v-bind:class="show_form_car ? 'in' : ''" v-show="show_form_car"
                     v-show-slide="show_form_car">

                  <hr class="mb-1 mt-1" />

                  <p class="mt-2 mb-1 font-small-xs">
                    <span v-if="car.owner_type === 'client'" class="label label-primary font-medium">
                      Авто владельца
                    </span>
                    <span v-if="car.owner_type === 'company'" class="label label-primary font-medium">
                      Авто компании
                    </span>
                    <span v-if="car.owner_type === 'exchange'" class="label label-primary font-medium">
                      Обмен/выкуп
                    </span>
                    <span class="label label-success font-medium">
                      Находится {{ car.location === "owner" ? "у владельца" : "в салоне" }}
                    </span>
                    <span v-if="car.contract_type === 'free'" class="label label-info font-medium mt-1">
                      Договор: бесплатно
                    </span>
                    <!--                    <span v-if="car.contract_type === 'fee'" class="label label-info font-medium mt-1">-->
                    <!--                      Договор: абонплата-->
                    <!--                    </span>-->
                    <span v-if="car.contract_type === 'compensation'" class="label label-info font-medium mt-1">
                      Договор: компенсация
                    </span>
                  </p>

                  <p v-if="car.is_top_price || car.is_checked || car.is_new || car.is_our" class="mt-2 mb-1 font-small">
                    <span v-if="car.is_our" class="label label-primary font-medium">
                      Выкупленное авто
                    </span>
                    <span v-if="car.is_top_price" class="label label-danger font-medium">
                      ТОП-цена
                    </span>
                    <span v-if="car.is_checked" class="label label-primary font-medium">
                      Проверено на СТО
                    </span>
                    <span v-if="car.is_new" class="label label-success font-medium">
                      Новая
                    </span>
                  </p>

                  <img v-if="car.main_photo" class="img-fluid mt-1 mb-1" :src="car.main_photo" :alt="car.title">

                  <p class="mt-0 mb-1 font-small">
                    <span class="label label-info font-small mt-1">
                      <span class="font-medium">{{ car.year }}</span> год
                    </span>
                    <span class="label label-info font-small font-medium">
                      {{ car.type.title }}
                    </span>
                    <span v-if="car.origin" class="label label-info font-small font-medium">
                      {{ car.origin.title }}
                    </span>
                    <span class="label label-info font-small font-medium">
                      {{ car.specification.fuel.title }}, {{ car.specification.engine_title }}
                    </span>
                    <span class="label label-info font-small font-medium">
                      {{ car.specification.gearbox.title }}
                    </span>
                  </p>

                  <p class="mt-0 mb-1 font-small">
                    <span class="label label-info font-small">
                      <span class="font-medium">{{ car.specification.race }}</span> тыс. км.
                    </span>
                    <span class="label label-info font-small">
                      <span class="font-medium">{{ car.specification.transmission.title }}</span> привод
                    </span>
                    <span class="label label-info font-small">
                      <span class="font-medium">{{ car.specification.capacity }}</span> мест
                    </span>
                  </p>

                  <p class="mt-0 mb-1">
                    <span class="label label-success font-scale-2">
                      <strong>{{ car.price }}</strong>$
                    </span>
                    <span v-if="car.old_price" class="label label-warning font-scale">
                      <strong>{{ car.old_price }}</strong>$ старая цена
                    </span>
                    <span v-if="car.price_for_owner" class="label label-info font-scale">
                      <strong>{{ car.price_for_owner }}</strong>$ в руки
                    </span>
                    <span v-if="car.minimum_price" class="label label-info font-scale">
                      <strong>{{ car.minimum_price }}</strong>$ минимальная
                    </span>
                    <span v-if="car.credit_percent" class="label label-primary font-scale-2">
                      <strong>{{ car.credit_percent }}</strong>%
                    </span>
                  </p>

                  <p class="mt-0 mb-1">
                      <span class="label label-primary font-medium">
                        {{ car.specification.vin_code }}
                      </span>
                    <span v-if="car.tech_passport" class="label label-primary font-medium">
                        {{ car.tech_passport }}
                      </span>
                  </p>

                  <hr class="mt-2 mb-1" />

                  <ul class="list-group list-group-unbordered">
                    <li v-if="car.branch" class="list-group-item font-scale-1">
                      <strong>Филиал</strong>

                      <a class="pull-right">
                        <strong>{{ car.branch.title }}</strong>
                      </a>
                      <br />
                      <span class="pull-right font-small">
                        <a class="font-medium">
                          <i class="fas fa-map-marker-alt"></i>
                          {{ car.branch.address }}
                        </a>
                      </span>
                    </li>
                    <li
                      v-if="car.owner && ([1, 2, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) || car.creator.id === $store.getters['auth/authUser'].id)"
                      class="list-group-item font-scale-1">
                      <strong>Владелец</strong>

                      <a class="pull-right">
                        <router-link :to="{ name: 'client.details', params: { clientId: car.owner.id }}">
                          <i class="fas fa-user-circle"></i>
                          <strong>
                            {{ car.owner.name }}
                          </strong>
                        </router-link>
                        <small>(ID: {{ car.owner.id }})</small>
                      </a>
                      <br />
                      <span class="pull-left font-small">
                        <i class="fa fa-phone-square font-small"></i>
                        <a :href="'tel:+' + car.owner.phone" class="font-medium">
                          {{ car.owner.phone }}
                        </a>
                      </span>
                      <br />
                      <a :href="'tel:+' + car.owner.phone" class="btn btn-primary pointer" style="float: right;">
                        <span>
                          <i class="fa fa-phone-square status-phone" />
                        </span>
                      </a>
                    </li>
                    <li
                      v-if="[1, 2, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) || car.creator.id !== $store.getters['auth/authUser'].id"
                      class="list-group-item font-scale">
                      <strong>Ответственный</strong>

                      <a class="pull-right">
                        <strong>
                          {{ car.creator.name }}
                        </strong>
                        <small>(ID: {{ car.creator.id }})</small>
                      </a>
                      <br />
                      <span class="pull-right font-small">
                        <a class="font-medium" v-if="car.creator.branch">
                          <i class="fas fa-map-marker-alt"></i>
                          {{ car.creator.branch.title }}
                        </a>
                      </span>
                    </li>
                    <li v-if="car.contract" class="list-group-item font-scale">
                      <strong>Договор компенсации</strong>

                      <br />
                      <span class="font-small">
                        Создан <strong>{{ car.contract.created_date }}</strong>
                      </span>
                      <br />
                      <span class="font-small">
                        Оплачен до
                        <strong :class="car.contract.is_expired ? 'text-red' : 'text-green'">
                          {{ car.contract.paid_date }}
                        </strong>
                      </span>
                    </li>
                    <li v-if="car.links && (car.links.damage_link || car.links.old_ads_link)" class="list-group-item">
                      <a v-if="car.links.damage_link"
                         class="pull-right font-scale"
                         :href="car.links.damage_link"
                         target="_blank">
                        <strong>Ссылка на повреждения</strong>
                      </a>
                      <a v-if="car.links.old_ads_link"
                         class="pull-right font-scale"
                         :href="car.links.old_ads_link"
                         target="_blank">
                        <strong>Ссылка на старую рекламу</strong>
                      </a>
                    </li>
                  </ul>

                  <hr class="mb-2 mt-2" />

                  <p class="text-muted text-right">
                    <small v-if="car.editor">
                      Обновил <strong>{{ car.editor.name }}</strong>
                      {{ car.updated_date }}
                    </small>
                    <small>
                      <br />
                      Создано <strong>{{ car.created_date }}</strong>
                    </small>
                  </p>
                </div>
              </transition>
            </div>
          </div>

          <div v-if="car.owner" class="box box-primary">
            <div class="box-header mb-0">
              <h5 class="box-title text-center pointer" v-on:click="toggleCarContract">
                <a class="collapsed">
                  <i v-bind:class="show_car_contract ? 'fa-caret-square-up' : 'fa-caret-square-down'"
                     class="font-scale-3 text-blue fas" />
                  Получить договор:
                  <span v-if="car.contract_type === 'free'">
                    бесплатный
                  </span>
                  <span v-if="car.contract_type === 'fee'">
                    абонплата
                  </span>
                  <span v-if="car.contract_type === 'compensation'">
                    компенсация
                  </span>
                </a>
              </h5>
            </div>
            <div class="box-body">
              <transition name="fade">
                <div class="collapse" v-bind:class="show_car_contract ? 'in' : ''"
                     v-show="show_car_contract" v-show-slide="show_car_contract">

                  <hr class="mb-3 mt-3" />

                  <a :href="apiURL + '/car/' + car.id + '/contract/page/1'"
                     class="btn btn-info right-side"
                     title="Получить документ"
                     target="_blank">
                    <strong>Страница 1</strong>
                  </a>
                  <a :href="apiURL + '/car/' + car.id + '/contract/page/2'"
                     class="btn btn-info pull-right"
                     title="Получить документ"
                     target="_blank">
                    <strong>Страница 2</strong>
                  </a>
                </div>
              </transition>
            </div>
          </div>

          <div
            v-if="([1, 2, 4, 6, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) || car.creator.id === $store.getters['auth/authUser'].id) && !car.is_deleted"
            class="box box-primary">
            <div class="box-header mb-0">
              <h5 class="box-title text-center pointer" v-on:click="toggleCarAutoRIA">
                <a class="collapsed">
                  <i v-bind:class="show_car_autoria ? 'fa-caret-square-up' : 'fa-caret-square-down'"
                     class="font-scale-3 text-blue fas" />
                  AutoRIA
                </a>
              </h5>
            </div>
            <div class="box-body">
              <transition name="fade">
                <div class="collapse" v-bind:class="show_car_autoria ? 'in' : ''"
                     v-show="show_car_autoria" v-show-slide="show_car_autoria">

                  <hr class="mb-3 mt-3" />

                  <button v-if="!car.autoria_id"
                          class="btn btn-success pull-right"
                          type="button"
                          title="Добавить авто в AutoRIA"
                          v-on:click="addCarOnAutoRIA"
                          :disabled="onProgressAutoRIA">
                    <strong>Добавить в AutoRIA</strong>
                    <i class="fas font-scale fa-circle-notch fa-spin margin-l-5" v-if="onProgressAutoRIA === true" />
                  </button>
                  <template v-else>
                    <div class="row">
                      <button class="btn btn-primary pull-right"
                              type="button"
                              title="Обновить фото к объявлению AutoRIA"
                              v-on:click="addCarPhotosOnAutoRIA"
                              :disabled="onProgressAutoRIAPhotos">
                        <strong>Обновить фото AutoRIA</strong>
                        <i class="fas font-scale fa-circle-notch fa-spin margin-l-5"
                           v-if="onProgressAutoRIAPhotos === true" />
                      </button>
                    </div>
                    <ul class="list-group list-group-unbordered">
                      <li class="list-group-item">
                        <strong>ID AutoRIA</strong>

                        <a class="pull-right">
                          <strong>{{ car.autoria_id }}</strong>
                        </a>
                      </li>

                      <template v-if="car_statistic.autoria.length">
                        <li class="list-group-item" v-if="car_statistic && car_statistic.autoria.views">
                          Просмотров объявления

                          <a class="pull-right">
                            <strong>{{ car_statistic.autoria.views }}</strong>
                          </a>
                        </li>
                        <li class="list-group-item" v-if="car_statistic && car_statistic.autoria.clicks">
                          Открытий номера телефона

                          <a class="pull-right">
                            <strong>{{ car_statistic.autoria.clicks }}</strong>
                          </a>
                        </li>
                        <li class="list-group-item" v-if="car_statistic && car_statistic.autoria.link">
                          <a :href="'https://auto.ria.com' + car_statistic.autoria.link"
                             target="_blank"
                             class="btn btn-info pull-right">
                            <strong>Перейти</strong>
                          </a>
                        </li>
                      </template>
                      <template v-else>
                        <li class="list-group-item">
                        <span class="text-muted font-medium">
                          Не удалось загрузить данные из AutoRIA
                        </span>
                        </li>
                      </template>
                    </ul>
                  </template>
                </div>
              </transition>
            </div>
          </div>

          <div
            v-if="([1, 2, 4, 6, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) || car.creator.id === $store.getters['auth/authUser'].id) && !car.is_deleted"
            class="box box-primary">
            <div class="box-header mb-0">
              <h5 class="box-title text-center pointer" v-on:click="toggleCarOLX">
                <a class="collapsed">
                  <i v-bind:class="show_car_olx ? 'fa-caret-square-up' : 'fa-caret-square-down'"
                     class="font-scale-3 text-blue fas" />
                  OLX
                </a>
              </h5>
            </div>
            <div class="box-body">
              <transition name="fade">
                <div class="collapse" v-bind:class="show_car_olx ? 'in' : ''"
                     v-show="show_car_olx" v-show-slide="show_car_olx">

                  <hr class="mb-3 mt-3" />

                  <button v-if="!car.olx_id"
                          class="btn btn-success pull-right"
                          type="button"
                          title="Добавить авто в OLX"
                          v-on:click="addCarOnOLX"
                          :disabled="onProgressOLX">
                    <strong>Добавить в OLX</strong>
                    <i class="fas font-scale fa-circle-notch fa-spin margin-l-5" v-if="onProgressOLX === true" />
                  </button>
                  <ul v-else class="list-group list-group-unbordered">
                    <li class="list-group-item">
                      <strong>ID OLX</strong>

                      <a class="pull-right">
                        <strong>{{ car.olx_id }}</strong>
                      </a>
                    </li>

                    <template v-if="car_statistic.olx.length">
                      <li class="list-group-item" v-if="car_statistic && car_statistic.olx.status">
                        Состояние

                        <a class="pull-right">
                          <strong>{{ car_statistic.olx.status }}</strong>
                        </a>
                      </li>
                      <li class="list-group-item" v-if="car_statistic && car_statistic.olx.valid_to">
                        Активно до

                        <a class="pull-right">
                          <strong>{{ car_statistic.olx.valid_to }}</strong>
                        </a>
                      </li>
                      <li class="list-group-item" v-if="car_statistic && car_statistic.olx.url">
                        <a :href="car_statistic.olx.url" target="_blank" class="btn btn-info pull-right">
                          <strong>Перейти</strong>
                        </a>
                      </li>
                    </template>
                    <template v-else>
                      <li class="list-group-item">
                        <span class="text-muted font-medium">
                          Не удалось загрузить данные из OLX
                        </span>
                      </li>
                    </template>
                  </ul>
                </div>
              </transition>
            </div>
          </div>

          <div
            v-if="([1, 2, 4, 6, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) || car.creator.id === $store.getters['auth/authUser'].id) && !car.is_deleted"
            class="box box-primary">
            <div class="box-header mb-0">
              <h5 class="box-title text-center pointer" v-on:click="toggleFormCarResponsible">
                <a class="collapsed">
                  <i v-bind:class="show_form_car_responsible ? 'fa-caret-square-up' : 'fa-caret-square-down'"
                     class="font-scale-3 text-blue fas" />
                  Ответственный
                </a>
              </h5>
            </div>
            <div class="box-body">
              <transition name="fade">
                <div class="collapse" v-bind:class="show_form_car_responsible ? 'in' : ''"
                     v-show="show_form_car_responsible" v-show-slide="show_form_car_responsible">

                  <hr class="mb-3 mt-3" />

                  <edit-responsible-form :car="car"></edit-responsible-form>
                </div>
              </transition>
            </div>
          </div>
        </div>

        <div class="col-xxl-9 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <vue-tabs class="panel no-border" v-model="currentTabItem" @tab-change="handleTabChange">
            <v-tab class="nav-tab-content"
                   key="comments"
                   :title="tabNames.comments">
              <car-comments :carId="car.id"
                            :isDeleted="car.is_deleted" />
            </v-tab>
            <v-tab class="nav-tab-content"
                   key="history"
                   :title="tabNames.history">
              <car-history v-if="currentTabItem === 1"
                           :carId="car.id" />
            </v-tab>
            <v-tab class="nav-tab-content"
                   key="equipment"
                   :title="tabNames.equipments">
              <car-equipments v-if="currentTabItem === 2"
                              :carCreator="car.creator"
                              :isDeleted="car.is_deleted"
                              :carId="car.id" />
            </v-tab>
            <v-tab class="nav-tab-content"
                   key="inspections"
                   :title="tabNames.inspections">
              <car-inspections v-if="currentTabItem === 3"
                               :carId="car.id"
                               :isDeleted="car.is_deleted"
                               :carResponsibleId="car.creator.id" />
            </v-tab>
            <v-tab class="nav-tab-content"
                   key="deposits"
                   :title="tabNames.deposits">
              <car-deposits v-if="currentTabItem === 4"
                            :carId="car.id" />
            </v-tab>
            <v-tab class="nav-tab-content"
                   key="photos"
                   :title="tabNames.photos">
              <car-photos v-if="currentTabItem === 5"
                          :carCreator="car.creator"
                          :carId="car.id"
                          :carTitle="car.title"
                          :isDeleted="car.is_deleted"
                          :carResponsibleId="car.creator.id" />
            </v-tab>
            <v-tab class="nav-tab-content"
                   key="description"
                   :title="tabNames.description">
              <car-description v-if="currentTabItem === 6"
                               :isDeleted="car.is_deleted"
                               :carId="car.id" />
            </v-tab>
            <v-tab class="nav-tab-content"
                   key="files"
                   :title="tabNames.files">
              <car-files v-if="currentTabItem === 7"
                         :carId="car.id"
                         :isDeleted="car.is_deleted"
                         :carResponsibleId="car.creator.id" />
            </v-tab>
            <v-tab class="nav-tab-content"
                   key="tasks"
                   :title="tabNames.tasks">
              <car-tasks v-if="currentTabItem === 8"
                         :carId="car.id" />
            </v-tab>
            <v-tab class="nav-tab-content"
                   key="weekly_reports"
                   :title="tabNames.weekly_reports">
              <car-weekly-reports :carId="car.id" />
            </v-tab>
            <v-tab v-if="car.owner"
                   class="nav-tab-content"
                   key="calls"
                   :title="tabNames.calls">
              <client-calls v-if="currentTabItem === 10"
                            :clientId="car.owner.id" />
            </v-tab>
            <v-tab v-if="car.contract"
                   class="nav-tab-content"
                   key="contract"
                   :title="tabNames.contract">
              <car-contract v-if="currentTabItem === 11"
                            :carId="car.id"
                            :carResponsibleId="car.creator.id"
                            :carContract="car.contract"/>
            </v-tab>
          </vue-tabs>
        </div>
      </div>
      <loading-processing v-else></loading-processing>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { VTab, VueTabs } from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";

Vue.component("edit-responsible-form", (resolve) => {
  return require(["./edit-responsible-form"], resolve);
});
Vue.component("car-comments", (resolve) => {
  return require(["./comments"], resolve);
});
Vue.component("car-history", (resolve) => {
  return require(["./history"], resolve);
});
Vue.component("car-equipments", (resolve) => {
  return require(["./equipments"], resolve);
});
Vue.component("car-inspections", (resolve) => {
  return require(["./inspections/index"], resolve);
});
Vue.component("car-deposits", (resolve) => {
  return require(["./deposits/index"], resolve);
});
Vue.component("car-photos", (resolve) => {
  return require(["./photos"], resolve);
});
Vue.component("car-description", (resolve) => {
  return require(["./description"], resolve);
});
Vue.component("car-files", (resolve) => {
  return require(["./files"], resolve);
});
Vue.component("car-tasks", (resolve) => {
  return require(["./tasks"], resolve);
});
Vue.component("car-weekly-reports", (resolve) => {
  return require(["./weekly_report"], resolve);
});
Vue.component("client-calls", (resolve) => {
  return require(["./../../clients/details/calls"], resolve);
});
Vue.component("car-contract", (resolve) => {
  return require(["./contract"], resolve);
});

export default {
  name: "car-details",

  components: {
    VueTabs,
    VTab,
  },

  metaInfo() {
    return {
      title: this.page_title,
    };
  },

  props: {
    carId: {
      type: [String, Number],
      required: true,
    },
    currentTab: {
      type: [String, null],
      required: false,
      default: null,
    },
  },

  data: () => ({
    page_title: "Автомобиль",

    car: null,
    car_statistic: null,

    relations: [
      "branch",
      "owner",
      "type",
      "origin",
      "specification",
      "specification.fuel",
      "specification.gearbox",
      "specification.transmission",
      "links",
      "contract",
      "creator",
      "editor",
    ],
    relations_count: [
      "comments",
      "history",
      "equipments",
      "inspections",
      "deposits",
      "photos",
      "files",
      "tasks",
      "weekly_reports",
    ],

    tabNames: {
      comments: "Комментарии",
      history: "История",
      equipments: "Комплектация",
      inspections: "Акты осмотра",
      deposits: "Задатки",
      photos: "Фотографии",
      description: "Описание",
      files: "Файлы",
      tasks: "Задачи",
      weekly_reports: "Еженедельные отчеты",
      calls: "Звонки",
      contract: "Договор",
    },

    currentTabItem: null,

    show_form_car: true,
    show_form_car_responsible: true,
    show_car_contract: true,
    show_car_autoria: true,
    show_car_olx: true,

    onProgressAutoRIA: false,
    onProgressAutoRIAPhotos: false,
    onProgressOLX: false,

    apiURL: process.env.VUE_APP_API_URL,
  }),

  async created() {
    try {
      this.EventBus.$on("car-details-reload", this.getCar);

      if (this.currentTab) {
        this.show_form_car = false;
        this.show_form_car_responsible = false;
        this.show_car_contract = false;
        this.show_car_autoria = false;
        this.show_car_olx = false;

        this.currentTabItem = this.currentTab;
      }

      await this.getCar();
    } catch (error) {
      errorHandler(error);
    }
  },

  mounted() {
    setTimeout(() => {
      this.tabNames.comments += " (" + this.car.comments_count + ")";
      this.tabNames.history += " (" + this.car.history_count + ")";
      this.tabNames.equipments += " (" + this.car.equipments_count + ")";
      this.tabNames.inspections += " (" + this.car.inspections_count + ")";
      this.tabNames.deposits += " (" + this.car.deposits_count + ")";
      this.tabNames.photos += " (" + this.car.photos_count + ")";
      this.tabNames.files += " (" + this.car.files_count + ")";
      this.tabNames.tasks += " (" + this.car.tasks_count + ")";
    }, 1500);
  },

  methods: {
    handleTabChange(tabIndex, newTab, oldTab) {
      this.currentTabItem = tabIndex;
    },
    async toggleFormCar() {
      this.show_form_car = this.show_form_car === false;
    },
    async toggleCarContract() {
      this.show_car_contract = this.show_car_contract === false;
    },
    async toggleCarAutoRIA() {
      this.show_car_autoria = this.show_car_autoria === false;
    },
    async toggleCarOLX() {
      this.show_car_olx = this.show_car_olx === false;
    },
    async toggleFormCarResponsible() {
      this.show_form_car_responsible = this.show_form_car_responsible === false;
    },
    async getCar() {
      try {
        const car_response = await API.apiClient.get("/car/" + this.carId, {
          params: {
            relations: this.relations,
            relations_count: this.relations_count,
          },
        });

        this.car = car_response.data;
        this.page_title = this.car.title;

        if (this.car.autoria_id || this.car.olx_id) {
          const car_statistic_response = await API.apiClient.get("/car/" + this.carId + "/statistic");
          this.car_statistic = car_statistic_response.data;
        }
      } catch (error) {
        errorHandler(error);
      }
    },
    async addCarOnAutoRIA() {
      try {
        this.onProgressAutoRIA = true;

        await API.apiClient.put("/car/" + this.car.id + "/autoria/add").then(() => {
          showSuccess();
          this.EventBus.$emit("car-history-reload");
          this.EventBus.$emit("car-details-reload");
        }).catch((error) => {
          errorHandler(error);
        });
      } catch (error) {
        errorHandler(error);
      }

      this.onProgressAutoRIA = false;
    },
    async addCarPhotosOnAutoRIA() {
      try {
        this.onProgressAutoRIAPhotos = true;

        await API.apiClient.put("/car/" + this.car.id + "/autoria/photos/add").then(() => {
          showSuccess();
          this.EventBus.$emit("car-history-reload");
          this.EventBus.$emit("car-details-reload");
        }).catch((error) => {
          errorHandler(error);
        });
      } catch (error) {
        errorHandler(error);
      }

      this.onProgressAutoRIAPhotos = false;
    },
    async addCarOnOLX() {
      try {
        this.onProgressOLX = true;

        await API.apiClient.put("/car/" + this.car.id + "/olx/add").then(() => {
          showSuccess();
          this.EventBus.$emit("car-history-reload");
          this.EventBus.$emit("car-details-reload");
        }).catch((error) => {
          errorHandler(error);
        });
      } catch (error) {
        errorHandler(error);
      }

      this.onProgressOLX = false;
    },
  },
};
</script>

<style scoped>

</style>
